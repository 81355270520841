import React from 'react';
import PropTypes from 'prop-types';

import Banner from '@components/envios/banner';
import Integrate from '@components/envios/integrate';
import Questions from '@components/envios/questions';

import Benefits from '../benefits';

const faqs = [
  {
    question: '¿En qué productos ofreceré Envíos de Mercado Libre?',
    answer: (
      <>
        En todos tus productos que puedan enviarse por correo. Si tienes dudas
        sobre algún producto, puedes revisar{' '}
        <a
          href="https://www.mercadolibre.com.pe/ayuda/Dimensiones-maximas-perm-tidas_3163"
          target="_blank"
          rel="noopener noreferrer"
        >
          cuáles son las dimensiones máximas permitidas para enviar paquetes
        </a>
        .
      </>
    ),
  },
  {
    question: '¿Cuándo ofreceré envío gratis?',
    answer: (
      <>
        Ofrecerás envíos gratis si tu producto es nuevo y el precio de la venta
        es S/. 39 o más; o si te compran varios productos y el total de la venta
        supera este monto.
        <br />
        <a
          href="https://www.mercadolibre.com.pe/ayuda/5247"
          target="_blank"
          rel="noopener noreferrer"
        >
          Revisar costos y descuentos
        </a>
      </>
    ),
  },
  {
    question: '¿Cuánto pagaré por los envíos?',
    answer: (
      <>
        El costo del envío dependerá del peso y del tamaño del paquete. Tendrás
        descuentos según tu reputación.
        <a
          href="https://www.mercadolibre.com.pe/ayuda/5247"
          target="_blank"
          rel="noopener noreferrer"
        >
          {' '}
          Revisar costos y descuentos
        </a>
      </>
    ),
  },
  {
    question: '¿Cuándo tendré disponible el dinero de mi venta?',
    answer: (
      <>
        Si tienes reputación por vender en Mercado Libre y el producto es nuevo,
        tendrás tu dinero disponible 2 días después de la entrega del producto.
        <br />
        <br />
        En cambio, si no tienes reputación por vender en Mercado Libre o el
        producto es usado, tendrás tu dinero disponible 6 días después de la
        entrega del producto.
        <br />
        <br />
        <a
          href="https://reputacion.mercadolibre.com.pe/myReputation"
          target="_blank"
          rel="noopener noreferrer"
        >
          Consultar mi reputación
        </a>
      </>
    ),
  },
  {
    question: '¿Dónde se despachan los productos?',
    answer: (
      <>
        Debes llevar tus productos a cualquiera de las{' '}
        <a
          href="https://envios.mercadolibre.com.pe/agenciesList/showAgenciesMap"
          target="_blank"
          rel="noopener noreferrer"
        >
          sucursales de tu transportadora asignada
        </a>
        .
      </>
    ),
  },
  {
    question: '¿Cuándo tengo que despachar los productos?',
    answer: (
      <>
        Una vez que vendas, prepara el producto y despáchalo a tiempo para
        brindar la mejor experiencia a las personas que te compran y no afectar{' '}
        <a
          href="https://www.mercadolibre.com.pe/ayuda/reputacion_vendedor_3437"
          target="_blank"
          rel="noopener noreferrer"
        >
          tu reputación por vender en Mercado Libre
        </a>
        . En cada venta te ayudaremos con indicaciones para que despaches a
        tiempo.
      </>
    ),
  },
];

const Body = (props) => {
  const { className } = props;

  return (
    <div className={className}>
      <Banner
        title="Envía tus ventas con Mercado Libre"
        text="Ofrecerás envíos gratis con hasta 50% de descuento y tus productos estarán destacados en todas las búsquedas."
      />
      <Benefits />
      <Questions title="Preguntas frecuentes" faqs={faqs} />
      <Integrate
        title={
          <>
            Seamos un equipo <br /> Envia tus ventas con Mercado Libre
          </>
        }
        link="https://www.mercadolibre.com.pe/publicaciones/"
        buttonText="Ir a Publicaciones"
      />
    </div>
  );
};

Body.propTypes = {
  className: PropTypes.string,
};

Body.defaultProps = {
  className: 'body',
};

export default Body;
